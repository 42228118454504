@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --channel-primary: 32 155 191;
    --channel-primary6: 29 139 171;
    --channel-primary7: 25 124 152;

    --channel-border: 220 220 220;

    --channel-background: 255  255 255;

    --channel-light: 246 250 253;

    --channel-light-dark: 186 230 242;

    --channel-secondary: 111 109 121;
    --channel-in-primary: 255  255 255;
    --channel-text: 1 1 1;
}

input::placeholder {
    color: rgb(var(--channel-secondary));
}

@font-face {
    font-family:'dkLongreach';
    src:url("../components/fonts/DKLongreach.otf");
}

@font-face {
    font-family:'Lato';
    src:url("../components/fonts/Lato-Regular.ttf")format('truetype');
}

body {
    margin: 0;
    font-family: -apple-system, 'Lato', serif ;/*-apple-system, BlinkMacSystemFont, 'dkLongreach','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.outline-font {
    color: white;
    -webkit-text-stroke: 1px black;
}
